.badge {
    background-color: $color-white;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    text-align: center;
    transition: all 300ms ease-out;
    transition-property: color, background-color;
    cursor: pointer;

    &:hover,
    &:focus {
        background: $color-cobalt;
        outline: 0;

        i {
            color: $color-white;
        }
    }

    i {
        line-height: 38px;
        font-size: 1.25rem;
        color: $color-cobalt;
    }
}