.off-canvas-content {
    
    header {
        height: $header-height-mobile;
        background-size: cover;
        background-position: center center;

        @include breakpoint(medium) {
            height: $header-height;
        }
        
        figure {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            overflow: hidden;
        }
    
        .grid-container {
            .grid-y {
                height: $header-height-mobile;

                @include breakpoint(medium) {
                    height: $header-height;
                }
            }
        }
    
        .top-bar {
            $top-bar: &;
            background-color: $color-cobalt;
            padding: 0;
            height: 72px;
            
            @include breakpoint(medium) {
                height: 64px;
            }
    
            ul {
                background-color: transparent;
            }
        
            .top-bar-left {
                flex: 0 0 auto;

                .logo {
                    position: relative;
                    z-index: 999;
                    height: 80px;
                    margin-left: 1rem;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05);
                    transition: all .3s ease;
                    
                    @include breakpoint(medium) {
                        height: 100px;
                        margin: -28px 0;
                        padding-left: 0;
                    }
                }
            }
        
            .top-bar-right {
                flex: 0 0 auto;
                position: relative;
    
                .menu {
                    color: $color-white;
                    
                    a, button {
                        color: $color-white;
                        transition: border-color 300ms ease-out;
                        height: 64px;
                        line-height: 64px;
                        padding: 0 1rem;
                        border-bottom: 5px solid $color-cobalt;
                        cursor: pointer;
        
                        &:hover,
                        &:focus {
                            border-bottom: 5px solid $color-white;
                            outline: 0;
                        }
                        
                        &[data-toggle] {
                            padding: 0 1.5rem;
                        }
                    }
    
                    i {
                        font-size: 2rem;
                        line-height: 64px;
    
                        @include breakpoint(medium) {
                            font-size: 1.25rem;
                        }
                    }
    
                    .off-canvas-toggle {
                        font-size: 0.75rem;
                        line-height: normal;
                        cursor: pointer;
                
                        i {
                            font-size: 2rem;
                            line-height: normal;
                            padding: 0;
                            display: block;
                        }
                    }
        
                    .is-active > a  {
                        background: transparent;
                        border-bottom: 5px solid $color-white;

                        &:hover,
                        &:focus {
                            border-color: $color-cerise;
                        }
                    }
                }
            }
        }
    
        .menu-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            padding: 0.5rem;
            max-width: 75rem;
            margin: 0 0.9375rem 60px 0.9375rem ;
    
            @include breakpoint(medium) {
                margin: 0 auto 100px auto;
            }
    
            li {
                padding: 0.25rem;
                flex-grow: 1;
                flex: 1 1 100%;
                
                @include breakpoint(medium) {
                    flex-basis: 50%;
                }
    
                @include breakpoint(large) {
                    flex-basis: 30%;
                }
    
                a {
                    background-color: $color-cerise;
                    color: $color-white;
                    text-align: center;
                    font-size: 1.125rem;
                    transition: background-color 300ms ease-out;
    
                    &:hover,
                    &:focus {
                        background-color: darken($color-cerise, 15);
                        text-decoration: underline;
                        outline: 0;
                    }
                }
            }
        }
    }
}