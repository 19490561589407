.pagination {
    margin: 0;

    li {
        margin-right: 0.3125rem;
        
        a,
        span {
            display: block;
            width: 28px;
            height: 28px;
            padding: 0;
            border-radius: 50%;
            line-height: 28px;
            text-align: center;
            text-decoration: none;
        }

        &.active {
            a,
            span {
                border-radius: 50%;
                background-color: $color-cobalt;
                color: $color-white;
                outline: 0;
            }
        }
        
        &:not(.disabled) {
            a,
            span {
                &:hover,
                &:focus {
                    border-radius: 50%;
                    background-color: $color-cobalt;
                    color: $color-white;
                    outline: 0;
                }
            }
        }

        &.disabled {
            a {
                cursor: not-allowed;
            }
        }
    }
	
	a {
        cursor: pointer;
        
        &:hover,
        &:focus {
            background: none;
        }
	}
}