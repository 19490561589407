.searchbar {
    position: absolute;
    right: 0;
    top: 64px;
    height: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    transition: all 300ms ease-out;
    z-index: 1;

    @media screen and (max-width: 768px) {
        width: 738px;
    }
    @media screen and (max-width: 425px) {
        width: 425px;
    }
    @include breakpoint(small only) { 
        width: 375px;
    }
    @media screen and (max-width: 320px) {
        width: 320px;
    }

    &.open {
        height: 64px;

        @media screen and (max-width: 768px) {
            width: 738px;
        }
        @media screen and (max-width: 425px) {
            width: 425px;
        }
        @include breakpoint(small only) { 
            width: 375px;
        }
        @media screen and (max-width: 320px) {
            width: 320px;
        }
    }

    input {
        max-width: 100%;
        height: 64px;
        border: none;
        padding: 0 1rem;
        margin-bottom: 0;
        -webkit-appearance: none;
        border-radius: 0;

        &:focus {
            border: none;
        }
    }

    button[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.25rem;
        line-height: 64px;
        padding: 0 1.5rem;
        cursor: pointer;
        background: $color-white;
        color: $color-cobalt;
    }
}