.filter {
    margin-top: 1.6875rem;

    li {
        line-height: 1.125rem;
        
        &:first-child {
            border-top: 1px solid $color-mercury;
        }
    }
}