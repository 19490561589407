.breadcrumbs {
    font-weight: $font-weight-medium;

    li {
        &:not(:last-child)::after {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1.5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 0.75rem;
        }
    }

    a {
        text-decoration: none;
        
        &:focus {
            text-decoration: underline;                                           
        }
    }
}