.off-canvas {
    background-color: $color-white;
    box-shadow: none !important;

    &.is-closed {
        // Override "visibility: hidden;" to fix a bug introduced in Safari 16.3 
        // for elements that have the properties "visibility: hidden; overflow-y: auto"
        visibility: initial;
    }

    header {
        background-color: $color-cobalt;
        height: 64px;

        .off-canvas.is-closed & {
            visibility: hidden;
        }

        .grid-container {
            padding: 0;
        }

        img {
            max-height: 24px;
            display: block;
            margin: 1.25rem auto;
        }

        .close-button {
            color: $color-white;
            font-size: 1rem;
            top: 0;
            right: 0;
            line-height: 64px;
            width: 64px;
            text-align: center;

            i:before {
                position: relative;
                top: 2px;
            }
        }
    }

    .is-drilldown {
        max-width: 100%;

        .off-canvas.is-closed & {
            visibility: hidden;
        }

        .drilldown {
            

            li {
                border-bottom: 1px solid $color-mercury;
                text-align: left;
            }

            a {
                background: none;
                color: $color-mineshaft;
                padding: 1rem 2rem 1rem 1rem;
            }

            .submenu {
                li.is-submenu-item {
                    font-weight: $font-weight-medium;

                    & ~ li.is-submenu-item {
                        font-weight: $font-weight-regular;
                    }
                }
            }

            .js-drilldown-back {
                background-color: $color-mercury;

                > a {
                    &:before {
                        font-family: 'icomoon' !important;
                        font-size: 0.875rem;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: '\edc5';
                        color: $color-mineshaft;
                        border: none;
                        margin-right: 1.25rem;
                        vertical-align: initial;
                    }
                }
            }

            .is-drilldown-submenu-parent > a {
                &:after {
                    font-family: 'icomoon' !important;
                    font-size: 0.875rem;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: '\edbd';
                    color: $color-mineshaft;
                    border: none;
                    margin-top: -7px;
                    right: 1.6875rem;
                }
            }
        }
    }
}