.ktc-checkbox {
    display: block;
    input[type="checkbox"] {
            position: relative; 
            margin: 5px 5px 0px 0px;
            left: 0;
    }
}

span[class="ktc-checkbox"] label {
    display: inline-block;
}

.ktc-radio {
    display: block;
    input[type="radio"] {
            margin: 0 0 0rem;
        }
}

input {
    
    &[type="email"] {
        &.error {
            border-color: $color-valencia;
        }
    }

    &[type="checkbox"] {
        position: absolute;
        left: -9999px;

        &.error {
            + label {
                color: $color-valencia;

                a {
                    color: $color-valencia;
                }

                i {
                    border-color: $color-valencia;
                }
            }
        }

        + label {
            padding: 0.75rem 0;
            margin: 0;
            display: block;
            font-size: 1rem;
            line-height: 1.5;
            
            i {
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                border: 2px solid $color-mineshaft;
                border-radius: 4px;
                margin-right: 0.625rem;
                vertical-align: middle;
                position: relative;
                top: -2px;
                transition: background-color 300ms ease-out;

                &:before {
                    content: '\ed71';
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1.5;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: $color-mineshaft;
                    opacity: 0;
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    width: 1.25rem;
                    height: 1.25rem;
                    text-align: center;
                    transition: all 300ms ease-out;
                    transition-property: color, opacity;
                }
            }
        }

        &:hover + label {
            i:before {
                opacity: 1;
            }
        }

        &:focus + label {
            outline: 0;
            
            i {
                outline-color: rgb(77, 144, 254); // #4D90FE
                outline-offset: -2px;
                outline-style: auto;
                outline-width: 5px;
            }
        }
        
        &:checked + label {
            i {
                background-color: $color-mineshaft;
                -webkit-animation: jello-horizontal 0.5s both;
                animation: jello-horizontal 0.5s both;

                &:before {
                    opacity: 1;
                    color: $color-white;
                }
            }
        }
    }

    &[type="submit"] {
        @include button();
        border-radius: 9999px;
        border: 0;
        margin-bottom: 0;
        float: right;
    
        &:focus {
            outline: 0;
        }
    }

    &.input-validation-error {
        border-color: $color-valencia;
    }
}

div[id^="form-"] {
    background-color: $color-white;
    overflow: hidden;

    .grid-container-widget & {
        padding: 1.6875rem;
    }
}

.form-field {
    margin-bottom: 1rem;
    label {
        font-size: inherit;
    }

    input[type="text"] {
        font-size: inherit;
    }
}

.field-validation-error {
    color: $color-valencia;
}

.error {
    color: $color-valencia
}