.card {
    $card: &;
    margin: 0;
    overflow: visible;
    width: 100%;

    &[onclick] {
        cursor: pointer;
    }

    &.card-pages {
        ul li img {
            display: none;
        }
    }

    .button {
        text-decoration: none;
    }

    a & {
        $a: &;

        #{$a}:hover {

            .card .card-label {
                background-color: darken($color-cerise, 15);
            }
        }
    }

    &-image {
        img {
            min-height: 200px;
            max-height: 200px;
            min-width: 100%;
            max-width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }

    &-list {
        list-style: none;
        margin: 0;

        li {
            position: relative;
            border-bottom: 1px solid $color-mercury;

            &:first-child {
                border-top: 1px solid $color-mercury;
            }

            p {
                padding: 1.6875rem;
                margin-bottom: 0;
            }

            a {
                padding: 1.6875rem;
                display: block;
                text-decoration: none;
                color: $color-mineshaft;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .card-label {
                    white-space: normal;
                }

                &:hover,
                &:focus {
                    outline: 0;
                    text-decoration: underline;
                    
                    .card-label {
                        background-color: darken($color-japaneselaurel, 15);
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include breakpoint(large) {

        &-horizontal {
            flex-direction: row;

            .card-section {
                flex: 1 1 100%;
            }
        }
    }

    &-news,
    &-events,
    &-activities {
        overflow: visible;

        .card-section {
            flex-grow: 0;
        }

        .card-list {
            li {
                a {
                    padding-left: 2.875rem;
                    padding-right: 1.6875rem;
                }
            }
        }

        .card-link {
            a {
                padding: 1.6875rem;
                text-decoration: none;
                color: $color-mineshaft;

                &:hover,
                &:focus {
                    outline: 0;
                    text-decoration: underline;
                }
            }
        }

        .card-label {
            position: absolute;
            color: $color-white;
            background-color: $color-japaneselaurel;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            text-align: center;
            text-transform: initial;
            padding: 0;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);
            word-spacing: 45px;
            transition: background-color 300ms ease-out;
        }
    }

    &-activities {
        
        .card-list {
            li {
                a {
                    &:hover,
                    &:focus {
                        outline: 0;
                        
                        .card-label {
                            background-color: darken($color-casablanca, 15);
                        }
                    }
                }
            }
        }

        .card-label {
            background-color: $color-casablanca;
            color: $color-mineshaft;
        }
    }

    &-contact {
        .badge {
            border: 0;
            text-decoration: none;
            background-color: $color-cobalt;

            i {
                font-size: 1rem;
                color: $color-white;
            }

            &:hover {
                background-color: darken($color-cobalt, 15);
                color: $color-white;

                i {
                    color: $color-white;
                }
            }
        }
    }

    &-newsletter {
        form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        input[type="email"] {
            width: 100%;
        }

        button[type="submit"] {
            width: 100%;
            min-width: 200px;

            @include breakpoint(small only) {
                min-width: auto;
            }
        }

        .flex-container {
            width: 100%;
            flex-wrap: wrap;

            [class^="flex-child"] {
                max-width: 100%;
            }
        }
    }

    &-link {
        display: flex;
        justify-content: space-around;
        
        a {             
            text-align: center;
            flex: 1 0 auto;

            ~ a {
                border-left: 1px solid $color-mercury;
            }
        }
    }

    &-section {
        padding: 1.6875rem;
        position: relative;
        overflow: visible;
        flex-grow: 0;

        .badge {
            border: 1px solid $color-mercury;
        }
    }

    &-label {
        position: absolute;
        top: -13px;
        background-color: $color-cerise;
        color: $color-white;
        text-transform: uppercase;
        padding: 6px 12px;
        font-size: 0.875rem;
        line-height: 0.875rem;
        display: inline-block;

        &.blue{
            background-color: $color-cobalt;
            color: $color-white;
        }

        &.green{
            background-color: $color-green;
            color: $color-white;
        }

        &.yellow{
            background-color: $color-yellow;
            color: $color-black;
        }
    }


    &-related {
        h3 {
            margin-bottom: 1.6875rem;
        }

        .subtitle {
            margin-bottom: 0.5rem;
        }

        .card-image {
            float: left;
            position: relative;
            margin: 0 0 1rem 0;

            @include breakpoint(large) {
                max-width: 200px;
                margin: 0 2rem 0 0;
            }
        }

        .card-label {
            position: absolute;
            color: $color-white;
            background-color: $color-japaneselaurel;
            font-size: 0.875rem;
            font-weight: $font-weight-medium;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            text-align: center;
            text-transform: initial;
            padding: 0;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            word-spacing: 45px;
            transition: background-color 300ms ease-out;
        }
    }

    &-results {
        margin-bottom: 0.75rem;
        border: none !important;

        .menu {
            li {
                display: flex;
                flex-wrap: wrap;
                transition: background-color 300ms ease-out;
                cursor: pointer;
                padding: 0;
                border-top: 1px solid $color-mercury;

                @include breakpoint(large) {
                    flex-wrap: nowrap;
                }
    
                &:last-child {
                    border-bottom: 1px solid $color-mercury;
                }
        
                p {
                    margin: 0;
                }

                a {
                    margin: 0;
                    padding: 1.6875rem;
                    width: 100%;
                    
                    &:focus,
                    &:hover {
                        background-color: lighten($color-mercury, 5);
                    }

                    i {
                        font-size: 1.75rem;
                        float: left;
                        margin-right: 1rem;
            
                        &:before {
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
        }

        .subtitle {
            margin-bottom: 0.5rem;
        }

        &-news,
        &-events,
        &-activities {
            .result-label {
                position: absolute;
                color: $color-white;
                background-color: $color-japaneselaurel;
                font-size: 0.875rem;
                font-weight: $font-weight-medium;
                line-height: 0.875rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                text-align: center;
                text-transform: initial;
                padding: 0;
                top: 20px;
                right: -10px;
                word-spacing: 45px;
                transition: background-color 300ms ease-out;
            }
        }

        &-activities {
            .result-list {
                li {
                    a {
                        &:hover,
                        &:focus {
                            outline: 0;
                            
                            .result-label {
                                background-color: darken($color-casablanca, 15);
                            }
                        }
                    }
                }
            }
    
            .result-label {
                background-color: $color-casablanca;
                color: $color-mineshaft;
            }
        }

        .result-image {
            position: relative;
            margin: 0 0 1rem 0;
            width: 100%;
            flex-grow: 0;
            flex-shrink: 0;

            @include breakpoint(large) {
                width: 180px;
                margin: 0 2rem 0 0;
            }

            img {
                min-width: 100%;
                max-width: 100%;
                min-height: 150px;
                max-height: 150px;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                
                @include breakpoint(large) {
                    min-width: 180px;
                    max-width: 180px;
                    min-height: 120px;
                    max-height: 120px;
                }
            }
        }

        .result-icon {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            padding-right: 1.6875rem;
            margin: 0 0 1rem 0;

            @include breakpoint(medium) {
                margin: 0;
            }

            i {
                color: $color-cerise;
                font-size: 4rem;
            }
        }
    }

    &-colorSMS {
        background-color: $color-cobalt;
        border-color: $color-cobalt;
        color: $color-white;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
            color: $color-white;
        }

        a {
            color: $color-white;
     
            &:hover,
            &:focus {
                color: $color-mineshaft;
            }
        }

        .menu {
            a {
                color: $color-white;

                &:hover,
                &:focus {
                    color: $color-mineshaft;
                }
            }

            &.menu-lines {
                li {
                    a {
                        border-color: transparentize($color-white, 0.75) !important;
                    }

                    &:last-child {
                        a {
                            border-color: transparentize($color-white, 0.75) !important;
                        }
                    }
                }
            }
        }
    }

    &-colorMelding {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-black;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
            color: $color-black;
        }

        a {
            color: $color-black;
        
            &:hover,
            &:focus {
                color: $color-oslo;
            }
        }

        .menu {
            a {
                color: $color-black;

                &:hover,
                &:focus {
                    color: $color-oslo;
                }
            }

            &.menu-lines {
                li {
                    a {
                        border-color: transparentize($color-white, 0.75) !important;
                    }

                    &:last-child {
                        a {
                            border-color: transparentize($color-white, 0.75) !important;
                        }
                    }
                }
            }
        }
    }

    &-color {
        background-color: $color-cerise;
        color: $color-white;
        border-color: $color-cerise;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
            color: $color-white;
        }

        a {
            color: $color-white;
     
            &:hover,
            &:focus {
                color: $color-mineshaft;
            }
        }

        .menu {
            a {
                color: $color-white;

                &:hover,
                &:focus {
                    color: $color-mineshaft;
                }
            }

            &.menu-arrows {
                li {
                    a {
                        &:after {
                            color: $color-white;
                        }
                    }
                }
            }
            &.menu-lines {
                li {
                    a {
                        border-color: transparentize($color-white, 0.75) !important;
                    }

                    &:last-child {
                        a {
                            border-color: transparentize($color-white, 0.75) !important;
                        }
                    }
                }
            }
        }
    }

    &-background {
        background-color: lighten($color-cobalt, 60%);
        border: 0;
    }

    &-landing {
        a {
            text-decoration: none;
        }

        .content{
            background-color: $color-white;
            padding:15px;
        }
    }

    &-block
    {
        a {
            text-decoration: none;
            color: $color-mineshaft;

            &:hover {
                h4 {
                    text-decoration: underline;
                }
            }
        }
    }
}