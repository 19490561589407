.menu {
    .is-current {
        font-weight: $font-weight-medium;
    }

    a {
        color: $color-mineshaft;
        text-decoration: none;
        line-height: 1.125rem;

        &:hover {
            outline: 0;
        }

        &.inline {
            display: inherit;
            flex-wrap: inherit;
            line-height: inherit;
            padding: 0;
            margin-bottom: 0.25rem;
        }
    }

    .subtitle {
        margin-right: 15px;
    }

    &#{&}-inline {

        li {
            padding-right: 1rem;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &#{&}-wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        li {
            flex: auto;
            flex-grow: 0;
            width: 50%;
            display: flex;
            align-content: center;
            justify-content: center;

            @include breakpoint (small only) {
                width: 100%;
            }

            &:nth-child(even) {
                padding-left: 0.75rem;

                @include breakpoint (small only) {
                    padding: 0;
                }
            }
            &:nth-child(odd) {
                padding-right: 0.75rem;

                @include breakpoint (small only) {
                    padding: 0;
                }
            }

            a {
                width: 100%;
            }
        }
    }

    &#{&}-icons {
        li {
            a {
                display: flex;
                flex-wrap: nowrap;
                height: 100%;

                &:hover {
                    text-decoration: none !important;
                }

                span:not(span ~ span) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;
                    max-width: 100%;

                    i {
                        font-size: 2rem;
                        text-decoration: none;
                    }
                }

                span ~ span {
                    padding-left: 1rem;
                    flex-shrink: 1;
                }
            }
        }
    }

    &#{&}-lines {

        &.menu-wrap {
            li {
                @include breakpoint(medium) {
                    &:nth-last-child(-n+2) {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        
        li {
            padding: 0;
            line-height: 1.125rem;

            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            a {
                border-bottom: 1px solid $color-mercury;
                align-self: center;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    &#{&}-arrows {
        li {
            a {
                padding-right: 2.5rem;
                position: relative;

                &:after {
                    content: '\edbd';
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    right: 0.75rem;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $color-cerise;
                    transition: right 300ms ease-out;
                }

                &:hover,
                &:focus {
                    &:after {
                        right: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    
    &#{&}-share {
        padding-top: 1.125rem;

        .subtitle {
            line-height: 40px;
        }

        .badge {
            border: 1px solid $color-mercury;
        }
    }
}

.accordion-menu {
    li {
        &[role="treeitem"] {
            border-bottom: 1px solid $color-mercury;

            > a,
            &:last-child {
                border-bottom: none !important;
            }
        }

        span {
            padding: 0.9375rem 0;
            display: inline-block;
            width: 100%;
        }
    }

    .submenu-toggle {
        
        &-text {
            padding: 0;
            display: block;
            width: 1px;
        }

        &::after {
            content: "\edc1";
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            border: none;
            font-size: 1rem;
            line-height: 1.6875rem;
            padding: 1rem 0;
            width: auto;
            height: auto;
        }
    }

    span,
    a {
        line-height: 1.6875rem;
    }

    .menu {
        display: none;
    }

    .nested.is-accordion-submenu {
        background-color: $color-mercury;
        padding: 0;
        margin-left: 0;
        width: 100%;

        .is-submenu-item {
            padding: 0 0.9375rem;
            border-color: darken($color-mercury, 5);
        }
    }

    .is-accordion-submenu {
        a {
            padding: 0.9375rem 0;
        }
    }
}