@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?9lnrlq');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?9lnrlq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?9lnrlq') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?9lnrlq') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?9lnrlq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bezwaar:before {
  content: "\e900";
}
.icon-bodem-water:before {
  content: "\e901";
}
.icon-contact:before {
  content: "\e902";
}
.icon-economie:before {
  content: "\e903";
}
.icon-e-formulier:before {
  content: "\e904";
}
.icon-energie:before {
  content: "\e905";
}
.icon-europa:before {
  content: "\e906";
}
.icon-gebiedsontwikkeling:before {
  content: "\e907";
}
.icon-kunst-cultuur:before {
  content: "\e908";
}
.icon-melding:before {
  content: "\e909";
}
.icon-natuur:before {
  content: "\e90a";
}
.icon-organisatie:before {
  content: "\e90b";
}
.icon-recreatie:before {
  content: "\e90c";
}
.icon-subsidies:before {
  content: "\e90d";
}
.icon-vergunning:before {
  content: "\e90e";
}
.icon-weg-water:before {
  content: "\e90f";
}
.icon-zoek-alfabet:before {
  content: "\e910";
}
.icon-phone:before {
  content: "\ea1d";
}
.icon-mail:before {
  content: "\ea32";
}
.icon-search:before {
  content: "\eb33";
}
.icon-menu:before {
  content: "\ec71";
}
.icon-cross-bold:before {
  content: "\ed6c";
}
.icon-cross:before {
  content: "\ed6d";
}
.icon-checkmark-bold:before {
  content: "\ed6f";
}
.icon-checkmark:before {
  content: "\ed71";
}
.icon-arrow-up:before {
  content: "\edb9";
}
.icon-arrow-right:before {
  content: "\edbd";
}
.icon-arrow-down:before {
  content: "\edc1";
}
.icon-arrow-left:before {
  content: "\edc5";
}
.icon-facebook:before {
  content: "\eeef";
}
.icon-instagram:before {
  content: "\eef1";
}
.icon-whatsapp:before {
  content: "\eef2";
}
.icon-twitter:before {
  content: "\eef5";
}
.icon-youtube:before {
  content: "\eefc";
}
.icon-linkedin:before {
  content: "\ef29";
}
.icon-twitter-x:before {
  content: "\eef6";
}
