main {
    display: block;
    background-color: #f0ebe8;
    min-height: 600px;

    &.home {
        .grid-container:first-child {
            position: relative;
            top: -70px;
            margin-bottom: -3.75rem;
        }    
    }

    > .grid-container {

        &:first-child {
            position: relative;
            top: -450px;
            margin-bottom: -450px;
        }
    }
}