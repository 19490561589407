.list {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 2rem;

    &-checkmark {
        li {
            margin-bottom: 0.875rem;

            &:before {
                content: '\ed6f';
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: relative;
                top: 1px;
                color: $color-japaneselaurel;
                padding-right: 0.625rem;
            }
        }
    }

    &-cross {
        li {
            margin-bottom: 0.875rem;

            &:before {
                content: '\ed6c';
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: relative;
                top: 1px;
                color: $color-valencia;
                padding-right: 0.625rem;
            }
        }
    }
}