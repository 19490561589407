.grid-container {
    padding: 0;

    .card {
        border: 1px solid $light-gray;
    }

    .grid-x {
        margin: 0 !important;
    }

    &-menu {
        padding-top: 0;
    }

    &-background {
        background-color: $color-white;
        padding: 1.125rem;
        margin-bottom: 1.125rem;

        .grid-padding-x {
            > .cell {
                @include breakpoint(small only) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .card {
            border: 1px solid $light-gray;

            &-background {
                border: 0;
            }

            &-content,
            &-breadcrumb,
            &-pages {
                border: none;

                .card-section {
                    padding: 0;
                }
            }
        }
    }

    &-widget {
        @include breakpoint(medium) {
            margin-left: -1.125rem;
            margin-right: -1.125rem;
        }

        .grid-padding-x {
            > .cell {
                @include breakpoint(small only) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .card {
            border: none;
        }
    }
}