.landing {
    .cell {
        img {
            padding-bottom: 1.5rem;
        }

        h2 {
            text-decoration: none;

            &:after {
                content: '\edbd';
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                float: right;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                display: block;
                color: $color-white;
                margin-top: -0.25rem;
                transition: all 300ms ease-out;
            }
        }


        &:first-child {
            a {
                &:hover,
                &:focus {
                    h2 {
                        outline: 0;
                        text-decoration: underline;

                        &:after {
                            background-color: darken($color-cerise, 15);
                        }
                    }
                }
            }
            
            h2 {
                color: $color-cerise;

                &:after {
                    background-color: $color-cerise;
                }
            }

            .menu.menu-arrows li a:after {
                color: $color-cerise;  
            }
        }

        &:nth-child(2) {
            a {
                &:hover,
                &:focus {
                    h2 {
                        outline: 0;
                        text-decoration: underline;
                        
                        &:after {
                            background-color: darken($color-cobalt, 15);
                        }
                    }
                }
            }

            h2 {
                color: $color-cobalt;

                &:after {
                    background-color: $color-cobalt;
                }
            }

            .menu.menu-arrows li a:after {
                color: $color-cobalt;  
            }
        }
    }
}