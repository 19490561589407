body {
    font-family: 'Fira Sans', sans-serif;
    font-weight: $font-weight-regular;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $color-mineshaft;

    @include breakpoint (small only) {
        word-break: break-word;
    }
}

h1, .h1 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: $font-weight-medium;
}

h2, .h2 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.75rem;
    font-weight: $font-weight-medium;
}

h3, .h3 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: $font-weight-medium;
}

h4, .h4 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: $font-weight-medium;
}

h5, .h5 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
}

h6, .h6 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 0.875rem;
}

.regular {
    font-weight: $font-weight-regular;
}

.medium {
    font-weight: $font-weight-medium;
}

.subtitle {
    color: $color-cerise;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
}

a {
    color: $color-cobalt;
    transition: color 300ms ease-out;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: darken($color-cobalt, 15);
    }
}