/* Generic Vue transitions */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

[v-cloak] {
    display: none !important;
}

.loader {
    position: relative;
    width: 100%;
    text-align: center;

    .animation {
        position: relative;
        display: inline-block;
        width: 64px;
        margin-bottom: 1rem;

        div {
            position: absolute;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
            &:nth-child(1) {
                background-color: $color-casablanca;
                left: 6px;
                z-index: 2;
                animation: square-1 0.6s infinite;
            }
            &:nth-child(2) {
                background-color: $color-cobalt;
                left: 6px;
                z-index: 2;
                animation: square-2 0.6s infinite;
            }
            &:nth-child(3) {
                background-color: $color-japaneselaurel;
                left: 26px;
                z-index: 2;
                animation: square-2 0.6s infinite;
            }
            &:nth-child(4) {
                background-color: $color-cerise;
                left: 45px;
                animation: square-3 0.6s infinite;
            }
        }
    }
}
    
    
@keyframes square-1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes square-3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes square-2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}