.cookie-consent {    
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999999;
    transition: all 0.25s ease-in-out;
    color: $color-white;
    background-color: darken($color-cobalt, 15);
    text-align: center;
    padding: 0.75rem 0;

    p {
        display: inline-block;

        @include breakpoint(medium) {
            margin: 0;
        }
    }

    .button-group {
        display: inline-block;
        margin-left: 0.75rem;
        margin-bottom: 0 !important;

        .button {
            background-color: $color-cobalt;
            color: $color-white;
            font-weight: $font-weight-medium;
            margin-bottom: 0;
            margin-right: 0.5rem;

            &:hover,
            &:focus {
                background-color: $color-white;
                color: $color-cobalt;
            }
        }
    }
}