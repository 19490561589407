.button {
    border-radius: 9999px;
    border: 0;
    margin-bottom: 0;

    &:focus {
        outline: 0;
    }

    .icon-align-left {
        margin-right: 0.3125rem;
        margin-left: 0;
    }

    i {
        font-size: 0.75rem;
        margin-left: 0.3125rem;
        
        &:before {
            position: relative;
        }
    }

    &--skip {
        position: absolute;
        top: 1rem;
        left: -1000px;
        z-index: 1000;

        &:focus {
            left: 1rem;
        }
    }
}

.button-group {
    flex-wrap: wrap;
    margin-bottom: -0.75rem !important;

    .button {
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
    }
}