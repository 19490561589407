footer {
    a {
        text-decoration: underline;
    }

    .grid-container {
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
        margin-bottom: 0;

        .cell {
            margin-bottom: 0;
        }
    }

    .card {
        border: 1px solid $light-gray;
    }

    .menu {   
        a {
            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: underline;
            }

            &.badge {
                &:hover,
                &:focus {
                    text-decoration: none;  
                }
            }
        }
    }

    .badge {
        &:hover,
        &:focus {
            background: darken($color-cobalt, 15);
        }
    }

    section {
        background-color: $color-white;
    }

    section:nth-child(2) {
        background-color: $color-cobalt;

        h1, h2, h3, h4, h5, h6, p {
            color: $color-white;
        }
        
        a {
            color: $color-white;
        }
    }
}