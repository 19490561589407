aside {
    background-color: $color-white;
    padding: 1.6875rem;
    margin-bottom: 1rem;
    position: relative;

    :last-child,
    &:last-child {
        margin-bottom: 0;
    }

    .title {
        line-height: 1.6;
        font-size: 2rem;
        font-weight: $font-weight-regular;
        background-color: $color-cerise;
        color: $color-white;
        position: relative;
        padding: 1.6875rem;
        margin: 0 -3.5rem;

        @include breakpoint(medium) {
            left: -3.5rem;
            margin-left: 0;
            margin-bottom: 1.6875rem;
        }

        input[type="text"] {
            margin: 0.5625rem 0 0 0;
            border: none;

            &:focus {
                border: none;
                outline: 0;
            }
        }
    }

    > .menu,
    p,
    h3 {
        margin-top: 1.6875rem;
    }

    input[type="text"] {
        margin-bottom: 0;
    }

    [data-toggle] {
        cursor: pointer;
        margin-top: 1.6875rem;

        i {
            &:before {
                position: relative;
                top: 4px;
            }
        }
    }

    h3 {
        ~ div {
            padding-top: 1.125rem;
        }
    }

    h5,
    .h5 {
        text-transform: uppercase;
    }
}