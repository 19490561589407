$color-white: #ffffff;
$color-black: #000000;
$color-mineshaft: #333333;
$color-cerise:  #e70077;
$color-cobalt: #0046ad;
$color-japaneselaurel: #048743;
$color-casablanca: #f9bd3f;
$color-mercury: #e9e9e9;
$color-valencia: #d91e18;
$color-oslo: #8c979a;
$color-yellow: #FDB827;
$color-green: #048743;

$font-weight-regular: 400;
$font-weight-medium: 500;

$header-height: 768px;
$header-height-mobile: 550px;